// ThemeContext.js

// import React, { createContext, useState } from "react";

// const ThemeContext = createContext({
//     theme: "light", // Default theme
//     toggleTheme: () => {},
// });

// export const ThemeProvider = ({ children }) => {
//     const [theme, setTheme] = useState("light");

//     const toggleTheme = (newTheme) => {
//         setTheme(newTheme);
//         // Optionally save theme preference to localStorage or database
//         localStorage.setItem("theme", newTheme);
//     };

//     return (
//         <ThemeContext.Provider value={{ theme, toggleTheme }}>
//             {children}
//         </ThemeContext.Provider>
//     );
// };

// export default ThemeContext;

// ThemeContext.js

import React, { createContext, useState, useContext } from "react";

// Create separate contexts for each flow
const SuperAdminThemeContext = createContext();
const AdminThemeContext = createContext();
const ManagerThemeContext = createContext();
const UserThemeContext = createContext();

export const SuperAdminThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState("light");

    const toggleTheme = (newTheme) => {
        setTheme(newTheme);
        // Save theme preference as needed
        localStorage.setItem("adminTheme", newTheme);
    };

    return (
        <SuperAdminThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </SuperAdminThemeContext.Provider>
    );
};

export const AdminThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState({
        primary: localStorage.getItem("adminThemePrimary") || "#FF6347",
        grade: localStorage.getItem("adminThemeGrade") || "#FFD700",
    });

    const toggleTheme = (primary, grade) => {
        setTheme({ primary, grade });
        localStorage.setItem("adminThemePrimary", primary);
        localStorage.setItem("adminThemeGrade", grade);
    };

    return (
        <AdminThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </AdminThemeContext.Provider>
    );
};

export const ManagerThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState("light");

    const toggleTheme = (newTheme) => {
        setTheme(newTheme);
        // Save theme preference as needed
    };

    return (
        <ManagerThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ManagerThemeContext.Provider>
    );
};

export const UserThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState("light");

    const toggleTheme = (newTheme) => {
        setTheme(newTheme);
        // Save theme preference as needed
    };

    return (
        <UserThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </UserThemeContext.Provider>
    );
};

// Custom hook to access specific flow's theme context
export const useSuperAdminTheme = () => useContext(SuperAdminThemeContext);
export const useAdminTheme = () => useContext(AdminThemeContext);
export const useManagerTheme = () => useContext(ManagerThemeContext);
export const useUserTheme = () => useContext(UserThemeContext);
