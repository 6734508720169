import axios from 'axios';
import Cookies from "js-cookie";

const fetchInstance = axios.create({

})

fetchInstance.interceptors.request.use(request => {
    request.headers["Accept"] = "application/json, text/plain, */*";
	request.headers["Authorization"] = request.url.includes('/auth/login') ? null : Cookies.get('ap_auth_token');
    return request;
})

export default fetchInstance