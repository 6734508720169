import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/css/index.scss";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import configureStore from "../src/store/configureStore";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap-icons/font/bootstrap-icons.scss";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./i18n/i18n";
import { StyledEngineProvider } from "@mui/material";
import "./index.css";
import { AdminThemeProvider } from "./context/ThemeContext";

const store = configureStore();
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <Provider store={store.store}>
            <PersistGate loading={null} persistor={store.persistor}>
                <StyledEngineProvider injectFirst>
                    <AdminThemeProvider>
                        <App />
                    </AdminThemeProvider>
                </StyledEngineProvider>
            </PersistGate>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
