import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';
import Cookies from "js-cookie";
import { getGatewayLogin } from './selectors';

function* login({ payload }) {
    try {
        yield put({ type: types.LOGIN_LOADING });
        if (payload.type === "ADMIN") {
            let result = yield call(api.adminlogin, payload.userName, payload.password);

            if (result.status === 200) {
                Cookies.set('ap_session_exp', false);
                Cookies.set('ap_auth_token', result.data.authorization);
                var userInfo = result.data;
                yield put({ type: types.LOGIN_SUCCESS, payload: { userInfo } });
            } else {
                yield put({ type: types.LOGIN_FAILURE, payload: result.response.data.message });
            }
        } else if (payload.type === "PARTNER") {
            let result = yield call(api.login, payload.userName, payload.password);

            if (result.status === 200) {
                Cookies.set('ap_session_exp', false);
                Cookies.set('ap_auth_token', result.data.authorization);
                var userInfo = result.data;
                yield put({ type: types.LOGIN_SUCCESS, payload: { userInfo } });
            } else {
                yield put({ type: types.LOGIN_FAILURE, payload: result.response.data.message });
            }
        }

    } catch (err) {
        localStorage.clear();
        yield put({ type: types.LOGIN_FAILURE, payload: "" });
    }
}

function* loginReset({ payload }) {
    try {
        Cookies.set('ap_auth_token', '');
        yield put({ type: types.LOGIN_RESET_SUCCESS, payload });
    } catch (err) {
        yield put({ type: types.LOGIN_RESET_FAILURE, payload: "error" });
    }
}

function* activeMenu({ payload }) {
    try {
        yield put({ type: types.MENU_ACTIVE_SUCCESS, payload });
    } catch (err) {
        yield put({ type: types.MENU_ACTIVE_FAILURE, payload: "error" });
    }
}

function* ping({ payload }) {
    try {
        //yield put({ type: types.LOGIN_LOADING });
        let result = yield call(api.ping);

        if (result.status === 200) {
            Cookies.set('ap_auth_token', result.data.authorization);
            var userInfo = result.data;
            yield put({ type: types.LOGIN_PING_SUCCESS, payload: { userInfo } });
        } else {
            Cookies.set('ap_session_exp', false);
            Cookies.set('ap_auth_token', null);
            yield put({ type: types.LOGIN_PING_FAILURE, payload: result.response.data.message });
        }

    } catch (err) {
        yield put({ type: types.LOGIN_PING_FAILURE, payload: "" });
    }
}

function* language({ payload }) {
    try {
        yield put({ type: types.LANGUAGE_SUCCESS, payload });
    } catch (err) {
        yield put({ type: types.LANGUAGE_FAILURE, payload: "error" });
    }
}

function* logout({ payload }) {
    try {
        let result = yield call(api.logout);
        if (result.status === 200) {
            Cookies.set('ap_session_exp', false);
            Cookies.set('ap_auth_token', null);
            yield put({ type: types.LOGOUT_SUCCESS, payload });
        } else {
            Cookies.set('ap_session_exp', false);
            Cookies.set('ap_auth_token', null);
            yield put({ type: types.LOGOUT_SUCCESS, payload });
        }
    } catch (err) {
        Cookies.set('ap_session_exp', false);
        Cookies.set('ap_auth_token', null);
        yield put({ type: types.LOGOUT_FAILURE, payload: "error" });
    }
}

function* getSystemFlags() {
    while (true) {
        const { isLoggedIn } = yield select(getGatewayLogin);
        if (isLoggedIn) {
            try {
                
                let result = yield call(api.getFlags);
                if (result.status === 200) {
                    yield put({ type: types.NOTIFICATION_COUNT_UPDATE, payload: result.data.notification_count });
                }
            } catch (err) {

            } finally {
                yield delay(600000);
            }
        }
    }
}

function* themeUpdate({ payload }) {
    try {
        let result = yield call(api.ping);
        if (result.status === 200) {
            Cookies.set('ap_auth_token', result.data.authorization);
            var userInfo = result.data;
            yield put({ type: types.CLIENT_THEME_SUCCESS, payload: { userInfo } });
        } else {
            yield put({ type: types.LOGIN_FAILURE, payload: result.response.data.message });
        }

    } catch (err) {
        yield put({ type: types.LOGIN_FAILURE, payload: "" });
    }
}

function* clear({ payload }) {
    try {
        yield put({ type: types.CLEAR_STATE_SUCCESS , payload });
    } catch (err) {
        yield put({ type: types.CLEAR_STATE_FAILURE, payload });
    }
}

function* loginSaga(data) {
    yield takeLatest(types.LOGIN_REQUEST, login);
    yield takeLatest(types.LOGIN_RESET_REQUEST, loginReset);
    yield takeLatest(types.MENU_ACTIVE_REQUEST, activeMenu);
    yield takeLatest(types.LOGIN_PING_REQUEST, ping);
    yield takeLatest(types.LANGUAGE_REQUEST, language);
    yield takeLatest(types.SYSTEM_GET_REQUEST, getSystemFlags);
    yield takeLatest(types.LOGOUT_REQUEST, logout);
    yield takeLatest(types.CLIENT_THEME_UPDATE, themeUpdate);
    yield takeLatest(types.CLEAR_STATE, clear);
}

export default loginSaga;
