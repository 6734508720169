import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const NetworkError = ({ onCloseNetwork, ...props }) => {
    const { t, i18n } = useTranslation();
    const userState = useSelector((state) => state.user.auth);
    return (
        <div className="px-2 fixed w-full h-screen flex justify-center items-center bg-black-13/75 z-50" style={{"--color-primary": userState?.userInfo?.primary_color, "--color-grade-50": userState?.userInfo?.secondary_color}}>
            <div className="w-[540px] bg-white rounded-md">
                <div className="bg-black-3 !px-4 !p-3 rounded-md">
                    <h5 className=" text-blue font-semibold">
                        {t("network_error")}
                    </h5>
                </div>
                <div className="modal_body card-body">
                    <div className="text-center py-2">
                        <div className="deleteModalContent my-3">
                            {t("network_error_message")}
                        </div>
                    </div>
                    <div className="text-center my-4 flex justify-center">
                        <button
                            className=" primary-hisa !px-6"
                            onClick={() => onCloseNetwork()}
                        >
                            {t("close")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NetworkError;
