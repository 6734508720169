export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_LOADING = 'LOGIN_LOADING';

export const LOGIN_RESET_REQUEST = 'LOGIN_RESET_REQUEST';
export const LOGIN_RESET_SUCCESS = 'LOGIN_RESET_SUCCESS';
export const LOGIN_RESET_FAILURE = 'LOGIN_RESET_FAILURE';

export const MENU_ACTIVE_REQUEST = 'MENU_ACTIVE_REQUEST';
export const MENU_ACTIVE_SUCCESS = 'MENU_ACTIVE_SUCCESS';
export const MENU_ACTIVE_FAILURE = 'MENU_ACTIVE_FAILURE';

export const LOGIN_PING_REQUEST = 'LOGIN_PING_REQUEST';
export const LOGIN_PING_SUCCESS = 'LOGIN_PING_SUCCESS';
export const LOGIN_PING_FAILURE = 'LOGIN_PING_FAILURE';

export const LANGUAGE_REQUEST = 'LANGUAGE_REQUEST';
export const LANGUAGE_SUCCESS = 'LANGUAGE_SUCCESS';
export const LANGUAGE_FAILURE = 'LANGUAGE_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SYSTEM_GET_REQUEST = 'SYSTEM_GET_REQUEST';
export const SYSTEM_GET_SUCCESS = 'SYSTEM_GET_SUCCESS';
export const SYSTEM_GET_FAILURE = 'SYSTEM_GET_FAILURE';

export const CLIENT_THEME_UPDATE = 'CLIENT_THEME_UPDATE';
export const CLIENT_THEME_SUCCESS = 'CLIENT_THEME_SUCCESS';

export const NOTIFICATION_COUNT_UPDATE = 'NOTIFICATION_COUNT_UPDATE';

export const CLEAR_STATE = 'CLEAR_STATE';
export const CLEAR_STATE_SUCCESS = 'CLEAR_STATE_SUCCESS';
export const CLEAR_STATE_FAILURE = 'CLEAR_STATE_FAILURE';