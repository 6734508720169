import axios from './fetchInstace';
import { getAuthToken } from '../components/common/common';

export function authApi(url, payload) {
  return axios({
    method: payload.method,
    url: window.location.hostname === "localhost" ? process.env.REACT_APP_AUTH_URL_LOCAL + url : process.env.REACT_APP_AUTH_URL + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null,
    headers: {
      "Accept": "application/json, text/plain, */*",
      "Authorization": url === "/auth/login" ? null : url === "/salary/download/excel" ? null : url === "/auth/admin-login" ? null : getAuthToken(),
    }
  }).then((data) => (data))
    .catch((Error) => {
      return { ...Error }
    }
    );
}