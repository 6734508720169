import Cookies from 'js-cookie';

export const getAuthToken = () => {

    let token = Cookies.get('ap_auth_token');
    if (token === "") {
        return null;
    } else if (token === undefined || token === "undefined") {
        return null;
    } else {
        return token;
    }
}

export const appURL = "https://www.hisabat.om/be-service";
export const appURLFE = "https://www.hisabat.om/registration";
export const hostname = "www.hisabat.om";
export const hostname_1 = "admin.hisabat.om";
export const hostname_2 = "partner.hisabat.om";