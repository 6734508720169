import React from "react";
import deleteModal from "../../../assets/images/public/del.png";
import expiryIcon from "../../../assets/images/expiryIcon.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Timeout = ({ onLogout, ...props }) => {
    const { t, i18n } = useTranslation();
    const userState = useSelector((state) => state.user.auth);
    return (
        <div className="custom_modalContainer px-2 fixed w-full h-screen flex justify-center items-center bg-black-13/75 z-50" style={{"--color-primary": userState?.userInfo?.primary_color, "--color-grade-50": userState?.userInfo?.secondary_color}}>
            <div className="w-[540px] bg-white rounded-md">
                <div className="bg-black-3 !px-4 !p-3 rounded-md">
                    <h5 className=" text-blue font-semibold">
                        {t("session_timeout")}
                    </h5>
                </div>
                <div className="modal_body card-body">
                    <div className="text-center py-2">
                        <div className="flex justify-center max-w-40 m-auto">
                            <img src={expiryIcon} alt="imagedelete" />
                        </div>
                        <div className="deleteModalContent my-3">
                            {t("session_timeout_message")}
                        </div>
                    </div>
                    <div className="text-center my-4 flex justify-center">
                        <button
                            className=" primary-hisa !px-6"
                            onClick={() => onLogout()}
                        >
                            {t("login_again")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Timeout;
